import React, {
  useCallback,
  useState,
} from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  IconButton,
  Typography,
} from '@material-ui/core';
import { Icon } from 'office-ui-fabric-react';
import {
  useDispatch,
} from 'react-redux';
import PropTypes from 'prop-types';

import {
  deleteTemplate,
} from 'Src/actions';
import EditTemplateDialog from 'Browser/EditTemplateDialog';
import DeleteTemplateConfirmationDialog from 'Src/components/ConfirmationDialog';
import Template from 'Src/data/Template';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  icon: {
    fontSize: 16,
  },
});

function Header({ template }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [isEditTemplateModalOpen, setIsEditTemplateModalOpen] = useState(false);
  const [
    isDeleteTemplateConfirmationModalOpen,
    setIsDeleteTemplateConfirmationModalOpen,
  ] = useState(false);

  function handleOpenEditModal() {
    setIsEditTemplateModalOpen(true);
  }

  function handleCloseEditModal() {
    setIsEditTemplateModalOpen(false);
  }

  const handleDeletionConfirmation = useCallback(
    () => dispatch(deleteTemplate(template.id)),
    [dispatch, template],
  );

  const handleDeletionCancellation = useCallback(
    () => setIsDeleteTemplateConfirmationModalOpen(false),
    [],
  );

  const handleDeleteTemplateClick = useCallback(
    () => setIsDeleteTemplateConfirmationModalOpen(true),
    [],
  );

  return (
    <div className={classes.root}>
      <div>
        <Typography
          variant="h6"
        >
          {template.name}
        </Typography>
      </div>
      <div>
        <IconButton
          onClick={handleOpenEditModal}
        >
          <Icon
            className={classes.icon}
            iconName="Edit"
          />
        </IconButton>
        <IconButton onClick={handleDeleteTemplateClick}>
          <Icon
            className={classes.icon}
            iconName="Delete"
          />
        </IconButton>
      </div>
      <EditTemplateDialog
        open={isEditTemplateModalOpen}
        onClose={handleCloseEditModal}
        template={template}
      />
      <DeleteTemplateConfirmationDialog
        title="Delete this template?"
        content="Are you sure you want to delete this template?"
        confirmationButtonText="Delete"
        isOpen={isDeleteTemplateConfirmationModalOpen}
        onCancellation={handleDeletionCancellation}
        onConfirmation={handleDeletionConfirmation}
      />
    </div>
  );
}

Header.propTypes = {
  template: PropTypes.instanceOf(Template),
};

Header.defaultProps = {
  template: new Template(),
};

export default Header;
