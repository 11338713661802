import {
  List, OrderedMap, fromJS,
} from 'immutable';
import shortId from 'shortid';

export default function listToOrderedMap(listArg,
  getKeyFn = () => shortId.generate(),
  getValueFn = el => el) {
  let list;
  if (List.isList(listArg)) {
    list = listArg;
  } else if (Array.isArray(listArg)) {
    list = new List(listArg);
  } else {
    throw new Error('Argument must be an Immutable List or an array');
  }

  return list.reduce((orderedMap, el) => {
    return orderedMap.set(getKeyFn(el), fromJS(getValueFn(el)));
  }, new OrderedMap());
}
