import React from 'react';
import {
  DialogContent,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  Typography,
} from '@material-ui/core';
import {
  makeStyles,
} from '@material-ui/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 288,
    minHeight: 228,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  basicPadding: {
    padding: theme.spacing(3),
  },
  lightText: {
    fontWeight: 300,
  },
  contentText: {
    fontSize: 14,
  },
  cancelButton: {
    backgroundColor: '#f4f4f4',
    color: '#212121',
    marginLeft: theme.spacing(),
  },
  buttons: {
    display: 'flex',
    justifyItems: 'flex-start',
    width: '100%',
  },
}));

function ConfirmationDialog({
  title,
  content,
  confirmationButtonText,
  cancellationButtonText,
  onConfirmation,
  onCancellation,
  isOpen,
}) {
  const classes = useStyles();

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      open={isOpen}
      classes={{
        paper: classes.root,
      }}
    >
      <div className={classes.content}>
        <DialogTitle>
          <Typography
            className={classes.lightText}
            component="span"
            variant="h5"
          >
            { title }
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography className={classes.contentText}>{ content }</Typography>
        </DialogContent>
        <DialogActions className={classes.basicPadding}>
          <div className={classes.buttons}>
            <Button
              onClick={onConfirmation}
              color="primary"
              variant="contained"
            >
              { confirmationButtonText }
            </Button>
            <Button
              onClick={onCancellation}
              color="primary"
              className={classes.cancelButton}
            >
              { cancellationButtonText }
            </Button>
          </div>
        </DialogActions>
      </div>
    </Dialog>
  );
}

ConfirmationDialog.propTypes = {
  content: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  cancellationButtonText: PropTypes.string,
  confirmationButtonText: PropTypes.string,
  isOpen: PropTypes.bool,
  onCancellation: PropTypes.func,
  onConfirmation: PropTypes.func,
};

ConfirmationDialog.defaultProps = {
  cancellationButtonText: 'Cancel',
  confirmationButtonText: 'Confirm',
  isOpen: false,
  onCancellation: () => {},
  onConfirmation: () => {},
};

export default ConfirmationDialog;
