import createSagaMiddleware from 'redux-saga';
import {
  withScope,
  captureException,
} from '@sentry/browser';

const middleware = createSagaMiddleware({
  onError: (error) => {
    withScope((scope) => {
      scope.setExtra('context', 'UNHANDLED_SAGA_ERROR');
      captureException(error);
    });
  },
});

export default middleware;
