import {
  call,
  select,
} from 'redux-saga/effects';
import {
  GrooveHTTPClient,
  HTTPMethod,
} from '@groove-labs/groove-http-client';
import { decamelizeKeys } from 'humps';

import {
  getCurrentUser,
  getStarLabel,
} from 'Src/store/selectors';

export default function* starEntity({
  id,
  type,
}) {
  const currentUser = yield select(getCurrentUser);
  const starLabel = yield select(getStarLabel);

  const labelableType = type;
  const labelableId = id;
  const labelOwnerType = 'User';
  const labelOwnerId = currentUser.id;
  const labelIds = [starLabel.id];

  // TODO @corytheboyd Should we move CRUD operations to GraphQL mutations?
  yield call(
    GrooveHTTPClient.makeRequest,
    {
      path: 'api/v2/label_instances/batch_create',
      method: HTTPMethod.POST,
      queryParameters: decamelizeKeys({
        labelableId,
        labelableType,
        labelIds,
        labelOwnerId,
        labelOwnerType,
      }),
    },
  );
}
