import {
  all,
  call,
  fork,
  put,
} from 'redux-saga/effects';

import initializeHttpClient from 'Src/sagas/initializeHttpClient';
import {
  watchSendUseTemplateMessageToParent,
  watchTemplateCreation,
  watchTemplateUpdate,
  watchTemplateDeletion,
  watchSettingFolder,
  watchFetchingSearchResults,
  watchSetStarredForFolder,
  watchSetStarredForTemplate,
  watchFetchingTemplateDialogData,
  watchFetchingSelectedTemplateData,
} from 'Src/sagas/watchers';
import watchSnackbarMessages from 'Src/sagas/watchSnackbarMessages';
import initializeData from 'Src/sagas/initializeData';
import {
  setLoadingState,
} from 'Src/actions';

import safe from './safe';

export default function* upstart() {
  try {
    yield call(initializeHttpClient);
    yield call(initializeData);
  } finally {
    yield put(setLoadingState(false));
  }

  yield all([
    safe(fork(watchSendUseTemplateMessageToParent)),
    safe(fork(watchSetStarredForFolder)),
    safe(fork(watchSetStarredForTemplate)),
    safe(fork(watchTemplateCreation)),
    safe(fork(watchTemplateUpdate)),
    safe(fork(watchTemplateDeletion)),
    safe(fork(watchSettingFolder)),
    safe(fork(watchFetchingSearchResults)),
    safe(fork(watchFetchingTemplateDialogData)),
    safe(fork(watchFetchingSelectedTemplateData)),
    safe(fork(watchSnackbarMessages)),
  ]);
}
