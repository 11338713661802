import {
  call,
} from 'redux-saga/effects';
import {
  toNumber,
} from 'lodash-es';
import { List } from 'immutable';

import listToOrderedMap from 'Utils/immutable/listToOrderedMap';
import Template from 'Data/Template';
import query from 'Src/sagas/fetchTemplatesInFolder/query.graphql';
import makeGraphQLRequest from 'Src/sagas/makeGraphQLRequest';

export default function* fetchTemplatesInFolder(folderId) {
  const response = yield call(makeGraphQLRequest, query, { folderId });
  const templates = response.message.getIn(['data', 'templateFolders', 0, 'templates', 'nodes']);

  return listToOrderedMap(
    List(templates.toJS().map(template => new Template({
      ...template,
      id: toNumber(template.id),
    }))),
    template => template.id,
  );
}
