import {
  call,
} from 'redux-saga/effects';
import { toNumber } from 'lodash-es';
import { List } from 'immutable';

import Folder from 'Data/Folder';
import User from 'Data/User';
import makeGraphQLRequest from 'Src/sagas/makeGraphQLRequest';
import query from 'Src/sagas/fetchTemplateFolders/query.graphql';

export default function* fetchTemplateFolders() {
  const response = yield call(makeGraphQLRequest, query);
  const templateFolders = response.message.getIn(['data', 'viewer', 'templateFolders', 'nodes'], List());

  return List(
    templateFolders.toJS().map(folder => new Folder({
      ...folder,
      id: toNumber(folder.id),
      ...(
        folder.user && {
          user: new User({
            ...folder.user,
            id: toNumber(folder.user.id),
          }),
        }
      ),
    })),
  );
}
