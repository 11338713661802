import {
  Record,
} from 'immutable';

const defaults = {
  id: null,
  name: null,
};

export default class Label extends Record(defaults) {
}
