import React, {
  useCallback,
} from 'react';
import {
  Button,
  List,
} from '@material-ui/core';
import {
  OpenInNew,
} from '@material-ui/icons';
import {
  makeStyles,
} from '@material-ui/styles';
import {
  useSelector,
} from 'react-redux';

import { getFolders } from 'Src/store/selectors';
import FolderListItem from 'Browser/FolderList/FolderListItem';
import ListHeader from 'Browser/Shared/ListHeader';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  main: {
    flexGrow: 1,
  },
  buttonRightIcon: {
    marginLeft: theme.spacing(1),
  },
  listRoot: {
    width: 220,
    padding: 0,
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

function FolderList() {
  const classes = useStyles();
  const folders = useSelector(getFolders);
  const handleTemplateManagementClick = useCallback(
    () => window.open(`${process.env.WEB_APPLICATION_BASE_URL}/templates`),
    [],
  );

  return (
    <div className={classes.root}>
      <ListHeader>
        Folders
      </ListHeader>
      <div className={classes.main}>
        <List
          classes={{
            root: classes.listRoot,
          }}
        >
          {folders.toList().map(folder => (
            <FolderListItem
              key={folder.id}
              folder={folder}
            />
          ))}
        </List>
      </div>
      <div className={classes.footer}>
        <Button
          variant="contained"
          color="default"
          fullWidth
          onClick={handleTemplateManagementClick}
        >
          Manage Templates
          <OpenInNew className={classes.buttonRightIcon} />
        </Button>
      </div>
    </div>
  );
}

export default FolderList;
