import React from 'react';
import {
  Typography,
} from '@material-ui/core';
import {
  makeStyles,
} from '@material-ui/styles';

import SadCone from './SadCone';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(12),
  },
  primaryText: {
    marginTop: theme.spacing(7),
    marginBottom: theme.spacing(4),
  },
}));

export default function Error() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <SadCone />
      <Typography
        className={classes.primaryText}
        variant="h4"
      >
        Whoops
      </Typography>
      <Typography
        variant="body1"
      >
        Something went wrong. We logged the error and are looking into it.
      </Typography>
    </div>
  );
}
