import React, { useCallback } from 'react';
import {
  Button,
} from '@material-ui/core';
import {
  useDispatch,
} from 'react-redux';
import {
  makeStyles,
} from '@material-ui/styles';

import {
  sendUseTemplateMessageToParent,
} from 'Src/actions';

const useStyles = makeStyles(theme => ({
  basicPadding: {
    padding: theme.spacing(2),
  },
}));

function Footer() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const sendMessage = useCallback(
    () => dispatch(sendUseTemplateMessageToParent()),
    [dispatch],
  );

  return (
    <div className={classes.basicPadding}>
      <Button
        color="primary"
        variant="contained"
        onClick={sendMessage}
      >
        Use Template
      </Button>
    </div>
  );
}

export default Footer;
