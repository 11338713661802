import React, {
  useCallback,
  useState,
} from 'react';
import {
  useDispatch,
} from 'react-redux';
import {
  InputAdornment,
  OutlinedInput,
} from '@material-ui/core';
import {
  Search,
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { useDebouncedCallback } from 'use-debounce';

import {
  fetchSearchResults,
} from 'Src/actions';

const DELAY_MILLISECONDS = 500;

const useStyles = makeStyles({
  root: {
    width: 500,
    height: 40,
  },
});

function SearchInput() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [
    searchTerm,
    setSearchTerm,
  ] = useState('');

  const [
    debouncedSearch,
  ] = useDebouncedCallback(value => dispatch(fetchSearchResults(value)), DELAY_MILLISECONDS);

  const handleChange = useCallback(
    (e) => {
      setSearchTerm(e.target.value);
      debouncedSearch(e.target.value);
    },
    [debouncedSearch],
  );

  return (
    <OutlinedInput
      classes={{
        root: classes.root,
      }}
      placeholder="Search for template by name or subject"
      startAdornment={(
        <InputAdornment position="start">
          <Search color="primary" />
        </InputAdornment>
      )}
      value={searchTerm}
      onChange={handleChange}
    />
  );
}

export default SearchInput;
