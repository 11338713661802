import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  useSelector,
} from 'react-redux';
import classNames from 'classnames';

import Body from 'Browser/TemplatePreviewPane/Body';
import Footer from 'Browser/TemplatePreviewPane/Footer';
import Header from 'Browser/TemplatePreviewPane/Header';
import {
  getSelectedTemplateData,
  getIsFetchingTemplateData,
} from 'Src/store/selectors';
import Loader from 'Src/components/Browser/Loader';

const BORDER_GREY = '#c8c6c5';
const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  loading: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  headerContainer: {
    padding: theme.spacing(2),
    borderBottom: `1px solid ${BORDER_GREY}`,
  },
}));

function TemplatePreviewPane() {
  const classes = useStyles();
  const selectedTemplate = useSelector(getSelectedTemplateData);
  const isFetchingSelectedTemplate = useSelector(getIsFetchingTemplateData);

  return (
    <div className={classNames(
      classes.root,
      {
        [classes.loading]: isFetchingSelectedTemplate,
      },
    )}
    >
      { isFetchingSelectedTemplate && <Loader /> }
      {
        !isFetchingSelectedTemplate
          && (
            <>
              <div className={classes.headerContainer}>
                <Header template={selectedTemplate} />
              </div>
              <Body template={selectedTemplate} />
              <Footer />
            </>
          )
      }
    </div>
  );
}

export default TemplatePreviewPane;
