import React, {
  useCallback,
  useMemo,
} from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  ListItemText,
} from '@material-ui/core';
import {
  KeyboardArrowRight,
} from '@material-ui/icons';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import ImmutablePropTypes from 'react-immutable-proptypes';

import {
  getSelectedFolderId,
} from 'Src/store/selectors';
import {
  setStarredForFolder,
  setSelectedFolderId,
} from 'Src/actions';
import Folder from 'Data/Folder';
import StarrableListItem from 'Browser/Shared/StarrableListItem';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  primary: {
    fontSize: 14,
    lineHeight: 'normal',
  },
  secondary: {
    fontSize: 12,
    lineHeight: 'normal',
  },
});

function FolderListItem({ folder }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const selectedFolderId = useSelector(getSelectedFolderId);

  const handleFolderSelection = useCallback(
    () => dispatch(setSelectedFolderId(folder.id)),
    [dispatch, folder.id],
  );
  const handleToggleStarred = useCallback(
    starred => dispatch(setStarredForFolder({
      id: folder.id,
      starred,
    })),
    [dispatch, folder],
  );
  const sharingDescription = useMemo(() => {
    if (folder.isShared) {
      if (folder.isOwned) {
        return 'Shared by You';
      }

      return `Shared by ${folder.user.name}`;
    }

    return null;
  }, [folder]);

  const isSelected = folder.id === selectedFolderId;
  const { isStarred } = folder;

  return (
    <StarrableListItem
      onClick={handleFolderSelection}
      onToggleStarred={handleToggleStarred}
      selected={isSelected}
      starred={isStarred}
    >
      <div className={classes.container}>
        <ListItemText
          primary={folder.name}
          secondary={sharingDescription}
          classes={{
            primary: classes.primary,
            secondary: classes.secondary,
          }}
        />
        <div>
          <KeyboardArrowRight />
        </div>
      </div>
    </StarrableListItem>
  );
}

FolderListItem.propTypes = {
  folder: ImmutablePropTypes.recordOf(Folder).isRequired,
};

export default FolderListItem;
