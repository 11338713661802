import React, {
  useCallback,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import {
  Snackbar as MuiSnackbar,
  IconButton,
} from '@material-ui/core';

import {
  getIsSnackbarOpen,
  getSnackbarMessage,
} from 'Src/store/selectors';
import {
  removeSnackbarMessage,
} from 'Src/actions';

export default function Snackbar() {
  const dispatch = useDispatch();
  const isOpen = useSelector(getIsSnackbarOpen);
  const message = useSelector(getSnackbarMessage);
  const handleOnClose = useCallback(() => {
    dispatch(removeSnackbarMessage());
  }, [dispatch]);

  return (
    <MuiSnackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={isOpen}
      autoHideDuration={3000}
      onClose={handleOnClose}
      message={message}
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={handleOnClose}
        >
          <CloseIcon />
        </IconButton>,
      ]}
    />
  );
}
