import {
  call,
  put,
} from 'redux-saga/effects';

import {
  setTemplateDialogData,
} from 'Src/actions';
import listToOrderedMap from 'Utils/immutable/listToOrderedMap';

import fetchAvailableMergeFields from './fetchAvailableMergeFields';
import fetchTemplateFolders from './fetchTemplateFolders';

export default function* handleFetchingTemplateDialogData() {
  const folders = yield call(fetchTemplateFolders);
  const mergeFields = yield call(fetchAvailableMergeFields);

  // TODO: @corytheboyd handle errors
  yield put(setTemplateDialogData({
    folders: listToOrderedMap(folders, f => f.id),
    mergeFields,
  }));
}
