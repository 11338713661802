import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import FolderList from 'Browser/FolderList';
import Header from 'Browser/Header';
import TemplateList from 'Browser/TemplateList';
import TemplatePreviewPane from 'Browser/TemplatePreviewPane';
import EmptyFolderCTA from 'Browser/EmptyFolderCTA';
import {
  getIsLoading,
  getIsSelectedFolderEmpty,
  getIsFetchingTemplates,
  getIsFetchingSearchResults,
} from 'Src/store/selectors';
import Loader from 'Src/components/Browser/Loader';
import Snackbar from 'Src/components/Browser/Snackbar';

const BORDER_GREY = '#c8c6c5';
const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '99vh',
    border: `1px solid ${BORDER_GREY}`,
  },
  loading: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  main: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    '&> :not(:last-child)': {
      borderRight: `1px solid ${BORDER_GREY}`, // TODO @corytheboyd Consider this customization if/when we touch on theming
    },
  },
  templatesLoadingContainer: {
    width: '100%',
    display: 'flex',
  },
});

function Browser() {
  const classes = useStyles();
  const isSelectedFolderEmpty = useSelector(getIsSelectedFolderEmpty);
  const isLoading = useSelector(getIsLoading);
  const isFetchingTemplates = useSelector(getIsFetchingTemplates);
  const isFetchingSearchResults = useSelector(getIsFetchingSearchResults);

  return (
    <div className={classNames(
      classes.root,
      {
        [classes.loading]: isLoading,
      },
    )}
    >
      { isLoading && <Loader /> }
      {
        !isLoading
          && (
            <>
              <div>
                <Header />
              </div>
              <div className={classNames(
                classes.main,
                {
                  [classes.loading]: isFetchingSearchResults,
                },
              )}
              >
                { isFetchingSearchResults && <Loader /> }
                {
                  !isFetchingSearchResults
                    && (
                      <>
                        <FolderList />
                        {
                          isFetchingTemplates
                            && (
                              <div className={classNames(
                                classes.templatesLoadingContainer,
                                {
                                  [classes.loading]: isFetchingTemplates,
                                },
                              )}
                              >
                                <Loader />
                              </div>
                            )
                        }
                        {
                          !isFetchingTemplates
                            && (
                              <>
                                <TemplateList />
                                {isSelectedFolderEmpty ? (
                                  <EmptyFolderCTA />
                                ) : (
                                  <TemplatePreviewPane />
                                )}
                              </>
                            )
                        }
                      </>
                    )
                }
              </div>
            </>
          )
      }
      <Snackbar />
    </div>
  );
}

export default Browser;
