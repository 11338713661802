import {
  put,
} from 'redux-saga/effects';

import {
  logError,
  showErrorPage,
} from 'Src/actions';

export default function* safe(effect) {
  try {
    yield effect;
  } catch (e) {
    console.error(e); // eslint-disable-line no-console

    // TODO: @jaebradley handle 401 when tackling what the error state should look lik
    yield put(logError({ error: e }));
    yield put(showErrorPage(true));
  }
}
