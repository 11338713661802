import {
  captureException,
  withScope,
} from '@sentry/browser';

export default function handleLoggingError({ payload: {
  error,
  stackTrace,
} }) {
  withScope((scope) => {
    scope.setExtra(stackTrace);
    captureException(error);
  });
}
