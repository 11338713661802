import React, {
  useCallback,
} from 'react';
import ErrorBoundary from 'react-error-boundary';
import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  logError,
} from 'Src/actions';
import {
  getShouldShowErrorPage,
} from 'Src/store/selectors';
import Browser from 'Browser';

import Error from './Error';

export default function App() {
  const dispatch = useDispatch();
  const shouldShowErrorPage = useSelector(getShouldShowErrorPage);
  const handleError = useCallback(
    (error, stackTrace) => dispatch(
      logError({
        error,
        stackTrace,
      }),
    ), [dispatch],
  );

  if (shouldShowErrorPage) {
    return (<Error />);
  }

  return (
    <ErrorBoundary
      FallbackComponent={Error}
      onErrorHandler={handleError}
    >
      <Browser />
    </ErrorBoundary>
  );
}
