import {
  Record,
} from 'immutable';

const defaults = {
  body: null,
  folderId: null,
  id: null,
  isStarred: false,
  name: null,
  linkClickCount: 0,
  openCount: 0,
  replyCount: 0,
  sentCount: 0,
  subject: null,
};

export default class Template extends Record(defaults) {}
