import { useState } from 'react';

/**
 * Yields [isHovered, hoverProps]. Hover props must be spread onto the target component.
 *
 * @return {Array.<*>}
 * */
function useHover() {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  return [isHovered, handleMouseEnter, handleMouseLeave];
}

export default useHover;
