import {
  select,
} from 'redux-saga/effects';

import {
  getContext,
  getSelectedTemplateData,
} from 'Src/store/selectors';
import { CONTEXT_BASE_URL_MAPPING } from 'Src/constants';

export default function* handleSendUseTemplateMessageToParent() {
  const context = yield select(getContext);

  const baseUrl = CONTEXT_BASE_URL_MAPPING[context];
  if (!baseUrl) {
    throw new Error(`Base URL not configured for context: ${context}`);
  }

  const template = yield select(getSelectedTemplateData);
  if (!template) {
    throw new Error('Template not selected. This should not happen');
  }

  const message = {
    type: 'INSERT_BODY',
    payload: {
      templateId: template.id,
    },
  };

  window.parent.postMessage(message, baseUrl);
}
