export const GROOVE_DATA_KEY_NAME = 'grooveStore';
export const GROOVE_ACCESS_TOKEN_KEY_NAME = 'GROOVE_ACCESS_TOKEN';

export function getData() {
  const json = window.localStorage.getItem(GROOVE_DATA_KEY_NAME);
  return json ? JSON.parse(json) : {};
}

export function get(key) {
  return getData()[key];
}

export function has(key) {
  return (key in getData());
}

export function set({
  key,
  value,
}) {
  const data = getData();
  data[key] = value;
  window.localStorage.setItem(GROOVE_DATA_KEY_NAME, JSON.stringify(data));
}

export function clearAll() {
  window.localStorage.removeItem(GROOVE_DATA_KEY_NAME);
}

export function setAccessToken(accessToken) {
  set({
    key: GROOVE_ACCESS_TOKEN_KEY_NAME,
    value: accessToken,
  });
}
