import {
  call,
  put,
  select,
} from 'redux-saga/effects';
import {
  GrooveHTTPClient,
  HTTPMethod,
} from '@groove-labs/groove-http-client';
import toNumber from 'lodash-es/toNumber';

import {
  fetchTemplates,
  setTemplates,
  failedTemplateCreation,
  createdTemplate,
} from 'Src/actions';
import {
  getIsInSearchMode,
} from 'Src/store/selectors';
import handleFetchingTemplate from 'Src/sagas/handleFetchingTemplate';

import fetchTemplatesInFolder from './fetchTemplatesInFolder';
import fetchSearchResults from './fetchSearchResults';

export default function* handleTemplateCreation({ payload }) {
  const {
    folderId,
    name,
    subject,
    body,
  } = payload;

  let response;

  try {
    response = yield call(
      GrooveHTTPClient.makeRequest,
      {
        path: 'api/v2/templates',
        method: HTTPMethod.POST,
        body: {
          name,
          subject,
          html_body: body,
          template_folder_id: folderId,
          template_type: 'email',
          template_type_value: 'Email',
        },
      },
    );
  } catch (e) {
    // TODO: @jaebradley handle specific error codes
    yield put(failedTemplateCreation());
    throw e;
  }

  yield put(createdTemplate());

  const isInSearchMode = yield select(getIsInSearchMode);
  if (isInSearchMode) {
    yield call(fetchSearchResults);
    return;
  }

  const createdTemplateId = toNumber(response.data.id);
  yield put(fetchTemplates(folderId));

  const templates = yield call(fetchTemplatesInFolder, folderId);
  yield put(setTemplates({
    templates,
    folderId,
    selectedTemplateId: createdTemplateId,
  }));
  yield call(handleFetchingTemplate, { payload: createdTemplateId });
}
