import {
  call,
  put,
  select,
} from 'redux-saga/effects';
import {
  GrooveHTTPClient,
  HTTPMethod,
} from '@groove-labs/groove-http-client';
import {
  decamelizeKeys,
} from 'humps';
import {
  List,
} from 'immutable';

import {
  setSearchResults,
} from 'Src/actions';
import {
  getSearchTerm,
  getSelectedFolderId,
} from 'Src/store/selectors';
import Folder from 'Data/Folder';
import listToOrderedMap from 'Utils/immutable/listToOrderedMap';
import {
  transformTemplatesData,
} from 'Data/transformers';
import calculateActiveItems from 'Utils/calculateActiveItems';

import handleFetchingTemplate from './handleFetchingTemplate';

export default function* fetchSearchResults() {
  const searchTerm = yield select(getSearchTerm);

  // TODO @corytheboyd Implement search in GraphQL
  const response = yield call(
    GrooveHTTPClient.makeRequest,
    {
      path: 'api/v2/template_folders/search',
      method: HTTPMethod.GET,
      queryParameters: decamelizeKeys({
        searchTerm,
        types: ['email'],
      }),
    },
  );

  const folders = listToOrderedMap(
    List(
      response.data.map(
        folder => new Folder({
          ...folder,
          templates: transformTemplatesData(folder.templates),
        }),
      ),
    ),
    folder => folder.id,
  );

  const currentFolderId = yield select(getSelectedFolderId);
  const {
    folderId: selectedFolderId,
    templateId: selectedTemplateId,
  } = calculateActiveItems({
    folders,
    currentFolderId,
  });

  yield put(
    setSearchResults({
      folders,
      selectedFolderId,
      selectedTemplateId,
    }),
  );
  yield call(handleFetchingTemplate, { payload: selectedTemplateId });
}
