import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    color: '#696969',
    paddingTop: theme.spacing(0.3),
    paddingBottom: theme.spacing(0.3),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
}));

function ListHeader({ children }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography
        variant="caption"
      >
        {children}
      </Typography>
    </div>
  );
}

ListHeader.propTypes = {
  children: PropTypes.string.isRequired,
};

export default ListHeader;
