export const ACTION_TYPES = {
  SET_STARRED_FOR_TEMPLATE: 'SET_STARRED_FOR_TEMPLATE',
  SET_STARRED_FOR_FOLDER: 'SET_STARRED_FOR_FOLDER',
  SEND_USE_TEMPLATE_MESSAGE_TO_PARENT: 'SEND_USE_TEMPLATE_MESSAGE_TO_PARENT',
  SET_AUTH_TOKEN: 'SET_AUTH_TOKEN',
  SET_CONTEXT: 'SET_CONTEXT',
  SET_FOLDERS: 'SET_FOLDERS',
  SET_SELECTED_FOLDER_ID: 'SET_SELECTED_FOLDER_ID',
  SET_SELECTED_TEMPLATE_ID: 'SET_SELECTED_TEMPLATE_ID',
  SET_STAR_LABEL: 'SET_STAR_LABEL',
  SET_LOADING_STATE: 'SET_LOADING_STATE',
  CREATE_TEMPLATE: 'CREATE_TEMPLATE',
  FAILED_TEMPLATE_CREATION: 'FAILED_TEMPLATE_CREATION',
  CREATED_TEMPLATE: 'CREATED_TEMPLATE',
  UPDATE_TEMPLATE: 'UPDATE_TEMPLATE',
  FAILED_TEMPLATE_UPDATE: 'FAILED_TEMPLATE_UPDATE',
  UPDATED_TEMPLATE: 'UPDATED_TEMPLATE',
  DELETE_TEMPLATE: 'DELETE_TEMPLATE',
  FAILED_TEMPLATE_DELETION: 'FAILED_TEMPLATE_DELETION',
  DELETED_TEMPLATE: 'DELETED_TEMPLATE',
  SET_CURRENT_USER: 'SET_CURRENT_USER',
  SEARCH_TEMPLATES: 'SEARCH_TEMPLATES',
  SET_ALL_FOLDER_DATA: 'SET_ALL_FOLDER_DATA',
  FETCH_TEMPLATE_DIALOG_DATA: 'FETCH_TEMPLATE_DIALOG_DATA',
  SET_TEMPLATE_DIALOG_DATA: 'SET_TEMPLATE_DIALOG_DATA',
  FETCH_SELECTED_TEMPLATE_DATA: 'FETCH_SELECTED_TEMPLATE_DATA',
  SET_SELECTED_TEMPLATE_DATA: 'SET_SELECTED_TEMPLATE_DATA',
  FETCH_TEMPLATES: 'FETCH_TEMPLATES',
  SET_TEMPLATES: 'SET_TEMPLATES',
  FETCH_SEARCH_RESULTS: 'FETCH_SEARCH_RESULTS',
  SET_SEARCH_RESULTS: 'SET_SEARCH_RESULTS',
  SET_SNACKBAR_MESSAGE: 'SET_SNACKBAR_MESSAGE',
  REMOVE_SNACKBAR_MESSAGE: 'REMOVE_SNACKBAR_MESSAGE',
  OPEN_SNACKBAR: 'OPEN_SNACKBAR',
  CLOSE_SNACKBAR: 'CLOSE_SNACKBAR',
  LOG_ERROR: 'LOG_ERROR',
  SHOW_ERROR_PAGE: 'SHOW_ERROR_PAGE',
};

export function setStarredForFolder({
  id,
  starred,
}) {
  return {
    type: ACTION_TYPES.SET_STARRED_FOR_FOLDER,
    payload: {
      id,
      starred,
    },
  };
}

export function setStarredForTemplate({
  id,
  starred,
}) {
  return {
    type: ACTION_TYPES.SET_STARRED_FOR_TEMPLATE,
    payload: {
      id,
      starred,
    },
  };
}

export function setAuthToken(token) {
  return {
    type: ACTION_TYPES.SET_AUTH_TOKEN,
    payload: token,
  };
}

export function setContext(context) {
  return {
    type: ACTION_TYPES.SET_CONTEXT,
    payload: context,
  };
}

export function setFolders(folders) {
  return {
    type: ACTION_TYPES.SET_FOLDERS,
    payload: folders,
  };
}

export function setAllFolderData({
  folders,
  selectedFolderId,
  selectedTemplateId,
}) {
  return {
    type: ACTION_TYPES.SET_ALL_FOLDER_DATA,
    payload: {
      folders,
      selectedFolderId,
      selectedTemplateId,
    },
  };
}

export function setSelectedFolderId(folderId) {
  return {
    type: ACTION_TYPES.SET_SELECTED_FOLDER_ID,
    payload: folderId,
  };
}

export function sendUseTemplateMessageToParent() {
  return {
    type: ACTION_TYPES.SEND_USE_TEMPLATE_MESSAGE_TO_PARENT,
  };
}

export function setSelectedTemplateId(templateId) {
  return {
    type: ACTION_TYPES.SET_SELECTED_TEMPLATE_ID,
    payload: templateId,
  };
}

export function setStarLabel(starLabel) {
  return {
    type: ACTION_TYPES.SET_STAR_LABEL,
    payload: starLabel,
  };
}

export function setLoadingState(state) {
  return {
    type: ACTION_TYPES.SET_LOADING_STATE,
    payload: state,
  };
}

export function createTemplate({
  folderId,
  name,
  subject,
  body,
}) {
  return {
    type: ACTION_TYPES.CREATE_TEMPLATE,
    payload: {
      folderId,
      name,
      subject,
      body,
    },
  };
}

export function failedTemplateCreation() {
  return {
    type: ACTION_TYPES.FAILED_TEMPLATE_CREATION,
  };
}

export function createdTemplate() {
  return {
    type: ACTION_TYPES.CREATED_TEMPLATE,
  };
}

export function updateTemplate({
  id,
  folderId,
  name,
  subject,
  body,
}) {
  return {
    type: ACTION_TYPES.UPDATE_TEMPLATE,
    payload: {
      id,
      folderId,
      name,
      subject,
      body,
    },
  };
}

export function failedTemplateUpdate() {
  return {
    type: ACTION_TYPES.FAILED_TEMPLATE_UPDATE,
  };
}

export function updatedTemplate() {
  return {
    type: ACTION_TYPES.UPDATED_TEMPLATE,
  };
}

export function deleteTemplate(id) {
  return {
    type: ACTION_TYPES.DELETE_TEMPLATE,
    payload: id,
  };
}

export function failedTemplateDeletion() {
  return {
    type: ACTION_TYPES.FAILED_TEMPLATE_DELETION,
  };
}

export function deletedTemplate() {
  return {
    type: ACTION_TYPES.DELETED_TEMPLATE,
  };
}

export function setCurrentUser(currentUser) {
  return {
    type: ACTION_TYPES.SET_CURRENT_USER,
    payload: currentUser,
  };
}

export function searchTemplates(searchTerm) {
  return {
    type: ACTION_TYPES.SEARCH_TEMPLATES,
    payload: searchTerm,
  };
}

export function setTemplateDialogData(folders) {
  return {
    type: ACTION_TYPES.SET_TEMPLATE_DIALOG_DATA,
    payload: folders,
  };
}

export function fetchTemplateDialogData() {
  return {
    type: ACTION_TYPES.FETCH_TEMPLATE_DIALOG_DATA,
  };
}

export function fetchSelectedTemplateData(selectedTemplateId) {
  return {
    type: ACTION_TYPES.FETCH_SELECTED_TEMPLATE_DATA,
    payload: selectedTemplateId,
  };
}

export function setSelectedTemplateData(templateData) {
  return {
    type: ACTION_TYPES.SET_SELECTED_TEMPLATE_DATA,
    payload: templateData,
  };
}

export function fetchTemplates(folderId) {
  return {
    type: ACTION_TYPES.FETCH_TEMPLATES,
    payload: folderId,
  };
}

export function setTemplates(folderId) {
  return {
    type: ACTION_TYPES.SET_TEMPLATES,
    payload: folderId,
  };
}

export function fetchSearchResults(searchTerm) {
  return {
    type: ACTION_TYPES.FETCH_SEARCH_RESULTS,
    payload: searchTerm,
  };
}

export function setSearchResults({
  folders,
  selectedFolderId,
  selectedTemplateId,
}) {
  return {
    type: ACTION_TYPES.SET_SEARCH_RESULTS,
    payload: {
      folders,
      selectedFolderId,
      selectedTemplateId,
    },
  };
}

export function setSnackbarMessage(message) {
  return {
    type: ACTION_TYPES.SET_SNACKBAR_MESSAGE,
    payload: message,
  };
}

export function removeSnackbarMessage() {
  return {
    type: ACTION_TYPES.REMOVE_SNACKBAR_MESSAGE,
  };
}

export function openSnackbar(message) {
  return {
    type: ACTION_TYPES.OPEN_SNACKBAR,
    payload: message,
  };
}

export function closeSnackbar() {
  return {
    type: ACTION_TYPES.CLOSE_SNACKBAR,
  };
}

export function logError({
  error,
  stackTrace,
}) {
  return {
    type: ACTION_TYPES.LOG_ERROR,
    payload: {
      error,
      stackTrace,
    },
  };
}

export function showErrorPage(showErrorPage) {
  return {
    type: ACTION_TYPES.SHOW_ERROR_PAGE,
    payload: showErrorPage,
  };
}
