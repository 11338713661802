import { call } from 'redux-saga/effects';

import starEntity from 'Src/sagas/starEntity';
import unstarEntity from 'Src/sagas/unstarEntity';
import { STARRABLE_ENTITY_TYPES_TO_LABELABLE_TYPE } from 'Src/constants';

export default function* setStarredForEntity({
  id, type: entityType, starred,
}) {
  const type = STARRABLE_ENTITY_TYPES_TO_LABELABLE_TYPE[entityType];
  if (starred) {
    yield call(starEntity, {
      id,
      type,
    });
  } else {
    yield call(unstarEntity, {
      id,
      type,
    });
  }
}
