export default function calculateActiveItems({
  folders,
  currentFolderId,
}) {
  if (folders.has(currentFolderId)) {
    const templates = folders.getIn([currentFolderId, 'templates'], null);
    if (templates && !templates.isEmpty()) {
      return {
        folderId: currentFolderId,
        templateId: templates.first().id,
      };
    }
  }

  if (folders.isEmpty()) {
    return {
      folderId: null,
      templateId: null,
    };
  }

  const firstFolder = folders.first();

  return {
    folderId: firstFolder.id,
    templateId: firstFolder.templates.isEmpty() ? null : firstFolder.templates.first().id,
  };
}
