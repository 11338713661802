import {
  take,
  fork,
  put,
  delay,
} from 'redux-saga/effects';

import {
  ACTION_TYPES,
  openSnackbar,
  closeSnackbar,
} from 'Src/actions';

const SNACKBAR_CLOSING_DELAY = 195;

function* handleMessage(message) {
  yield put(openSnackbar(message));
  yield take(ACTION_TYPES.REMOVE_SNACKBAR_MESSAGE);
  yield delay(SNACKBAR_CLOSING_DELAY);
  yield put(closeSnackbar());
}

export default function* watchSnackbarMessages() {
  while (true) {
    const {
      payload: message,
    } = yield take(ACTION_TYPES.SET_SNACKBAR_MESSAGE);
    yield fork(handleMessage, message);
  }
}
