import {
  applyMiddleware,
  compose,
  createStore,
} from 'redux';

import {
  isDevelopment,
} from 'Src/environment';

import reducer from './reducer';
import middlewares from './middlewares';


let composeMiddlewares = compose;

if (isDevelopment()) {
  // eslint-disable-next-line import/no-extraneous-dependencies,global-require
  const { composeWithDevTools } = require('redux-devtools-extension');
  composeMiddlewares = composeWithDevTools;
}

export default createStore(reducer, undefined, composeMiddlewares(applyMiddleware(...middlewares)));
