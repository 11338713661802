import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  ListItem,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import StarButton from 'Browser/Shared/Star'; // eslint-disable-line no-unused-vars
import useHover from 'Utils/hooks/useHover';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    height: 62,
  },
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    '&> :not(:last-child)': {
      marginRight: theme.spacing(1),
    },
  },
  contentContainer: {
    width: '100%',
  },
  hidden: {
    visibility: 'hidden',
  },
}));

function StarrableListItem({
  children,
  onClick,
  onToggleStarred,
  selected,
  starred,
}) {
  const classes = useStyles();
  const [
    // TODO @corytheboyd Revert https://github.com/GrooveLabs/templates/pull/111
    isHovered, // eslint-disable-line no-unused-vars
    onMouseEnter,
    onMouseLeave,
  ] = useHover();

  const handleStarClick = useCallback((event) => { // eslint-disable-line no-unused-vars
    event.stopPropagation();
    onToggleStarred(!starred);
  }, [onToggleStarred, starred]);

  return (
    <ListItem
      button
      disableGutters
      selected={selected}
      onClick={onClick}
      className={classes.root}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className={classes.container}>
        <div
          className={classNames({
            [classes.hidden]: true, // TODO @corytheboyd Revert https://github.com/GrooveLabs/templates/pull/111
          })}
        >
          {/* <StarButton
            starred={starred}
            onClick={handleStarClick}
          /> */}
        </div>
        <div className={classes.contentContainer}>
          {children}
        </div>
      </div>
    </ListItem>
  );
}

StarrableListItem.propTypes = {
  children: PropTypes.element.isRequired,
  onClick: PropTypes.func,
  onToggleStarred: PropTypes.func,
  selected: PropTypes.bool,
  starred: PropTypes.bool,
};

StarrableListItem.defaultProps = {
  onClick: () => {},
  onToggleStarred: () => {},
  selected: false,
  starred: false,
};

export default StarrableListItem;
