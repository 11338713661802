import React from 'react';

export default function SadCone() {
  /* eslint-disable max-len,react/jsx-max-props-per-line */
  return (
    <svg width="125" height="158" viewBox="0 0 125 158" xmlns="http://www.w3.org/2000/svg">
      <g id="Oops-screen" fill="none" fillRule="evenodd">
        <g id="Empty-State---Oops-screen" transform="translate(-578 -101)">
          <g id="Page-1" transform="translate(580 103)">
            <path
              d="M121,132.5 C121,145.478451 94.1372727,156 61,156 C27.8627273,156 1,145.478451 1,132.5 C1,119.521549 27.8627273,109 61,109 C94.1372727,109 121,119.521549 121,132.5"
              id="Fill-1"
              fill="#D2EDF1"
            />
            <path
              d="M112.959414,129.851354 C108.143977,128.729723 104.240135,123.946954 104.354767,119.093692 C104.805105,99.9715385 82.2836391,90 63.2293233,90 C45.2721955,90 24.1463233,97.5239385 23.2329098,116.126769 C23.0518647,119.825385 19.8967669,123.230431 16.1557744,123.720308 C4.05759398,125.304154 0,127.151231 0,131.938462 C0,137.447569 7.71579699,137.953508 22.7525489,138.911846 C25.5355489,139.089415 28.0174135,140.385938 30.2290752,142.051877 C34.5714286,145.323077 45.5014586,148 60.9548872,148 C74.6015038,148 83.2616466,145.983385 91.887218,141.753846 C93.7067669,140.861538 100.984962,137.292308 106.919421,137.292308 L110.082707,137.292308 C112.837504,137.292308 110.074519,137.292308 110.082707,137.292308 C117.360902,137.292308 121,138.065046 121,134.615385 C121,132.704954 117.877654,130.996185 112.959414,129.851354"
              id="Fill-3"
              fill="#FFF"
            />
            <path
              d="M112.959414,129.851354 C108.143977,128.729723 104.240135,123.946954 104.354767,119.093692 C104.805105,99.9715385 82.2836391,90 63.2293233,90 C45.2721955,90 24.1463233,97.5239385 23.2329098,116.126769 C23.0518647,119.825385 19.8967669,123.230431 16.1557744,123.720308 C4.05759398,125.304154 0,127.151231 0,131.938462 C0,137.447569 7.71579699,137.953508 22.7525489,138.911846 C25.5355489,139.089415 28.0174135,140.385938 30.2290752,142.051877 C34.5714286,145.323077 45.5014586,148 60.9548872,148 C74.6015038,148 83.2616466,145.983385 91.887218,141.753846 C93.7067669,140.861538 100.984962,137.292308 106.919421,137.292308 L110.082707,137.292308 C112.837504,137.292308 110.074519,137.292308 110.082707,137.292308 C117.360902,137.292308 121,138.065046 121,134.615385 C121,132.704954 117.877654,130.996185 112.959414,129.851354 Z"
              id="Stroke-5"
              stroke="#04A2BA"
              strokeWidth="3"
              strokeLinejoin="round"
            />
            <path
              d="M41,117.999502 C41,120.209282 39.4334,122 37.5,122 C35.5666,122 34,120.209282 34,117.999502 C34,115.790718 35.5666,114 37.5,114 C39.4334,114 41,115.790718 41,117.999502"
              id="Fill-7"
              fill="#046271"
            />
            <path
              d="M65,121.999502 C65,124.209282 63.4334,126 61.5,126 C59.5666,126 58,124.209282 58,121.999502 C58,119.790718 59.5666,118 61.5,118 C63.4334,118 65,119.790718 65,121.999502"
              id="Fill-9"
              fill="#046271"
            />
            <path
              d="M43,129.139838 C43.859402,128.312256 45.1525302,127.86335 46.5201265,128.037028 C48.3164175,128.265752 49.6890454,129.499533 50,131"
              id="Stroke-11"
              stroke="#046271"
              strokeWidth="3"
              strokeLinecap="round"
            />
            <path
              d="M94.8704824,1.63096145 L37.6096889,88.0173613 C36.2471818,90.0730926 37.2738404,92.8661463 39.6411624,93.5413398 L100.377851,110.858821 C102.745173,111.534014 105.081549,109.699388 104.997815,107.230683 L101.52192,3.52771625 C101.402689,-0.0154509493 96.8264127,-1.31924146 94.8704824,1.63096145"
              id="Fill-13"
              fill="#FFF"
            />
            <path
              d="M94.8704824,1.63096145 L37.6096889,88.0173613 C36.2471818,90.0730926 37.2738404,92.8661463 39.6411624,93.5413398 L100.377851,110.858821 C102.745173,111.534014 105.081549,109.699388 104.997815,107.230683 L101.52192,3.52771625 C101.402689,-0.0154509493 96.8264127,-1.31924146 94.8704824,1.63096145 Z"
              id="Stroke-15"
              stroke="#04A2BA"
              strokeWidth="3"
              strokeLinejoin="round"
            />
            <path
              d="M99.5691512,115.615864 C99.5691512,115.615864 83.3755746,112.716536 66.9186878,108.249159 C43.6011078,101.919779 36.058368,97.9884867 36.058368,97.9884867 C31.9596889,96.8501989 29.3046445,93.5121746 30.1604026,90.5699599 L30.4237128,89.6639758 C31.2785566,86.7226545 35.3315221,85.246633 39.4311155,86.3840273 C39.4311155,86.3840273 49.5475295,87.6992232 72.7371115,94.2287419 C86.7400937,98.1716492 102.940984,104.013192 102.940984,104.013192 C107.040578,105.150586 109.694708,108.487717 108.839864,111.429932 L108.577468,112.335916 C107.72171,115.27813 103.66783,116.753258 99.5691512,115.615864"
              id="Fill-17"
              fill="#FFF"
            />
            <path
              d="M99.5691512,115.615864 C99.5691512,115.615864 83.3755746,112.716536 66.9186878,108.249159 C43.6011078,101.919779 36.058368,97.9884867 36.058368,97.9884867 C31.9596889,96.8501989 29.3046445,93.5121746 30.1604026,90.5699599 L30.4237128,89.6639758 C31.2785566,86.7226545 35.3315221,85.246633 39.4311155,86.3840273 C39.4311155,86.3840273 49.5475295,87.6992232 72.7371115,94.2287419 C86.7400937,98.1716492 102.940984,104.013192 102.940984,104.013192 C107.040578,105.150586 109.694708,108.487717 108.839864,111.429932 L108.577468,112.335916 C107.72171,115.27813 103.66783,116.753258 99.5691512,115.615864 Z"
              id="Stroke-19"
              stroke="#04A2BA"
              strokeWidth="3"
              strokeLinejoin="round"
            />
            <path
              d="M113.632202,150.521049 C112.073723,152.431686 107.452219,151.905 103.626692,152.463703 C99.7137567,152.788679 96.4145422,153.45304 93.6211825,152.528538 C90.8752467,152.029067 90.8492101,146.693373 99.6932993,144.377716 C108.923289,142.404646 115.679797,148.71607 113.632202,150.521049"
              id="Fill-21"
              fill="#FFF"
            />
            <path
              d="M113.632202,150.521049 C112.073723,152.431686 107.452219,151.905 103.626692,152.463703 C99.7137567,152.788679 96.4145422,153.45304 93.6211825,152.528538 C90.8752467,152.029067 90.8492101,146.693373 99.6932993,144.377716 C108.923289,142.404646 115.679797,148.71607 113.632202,150.521049 Z"
              id="Stroke-23"
              stroke="#04A2BA"
              strokeWidth="3"
              strokeLinejoin="round"
            />
            <path
              d="M87.5,47.5428571 L79.5,84.4571429"
              id="Line"
              stroke="#E0F0F3"
              strokeWidth="3"
              strokeLinecap="round"
            />
            <path
              d="M93.5,71.5526316 L93,91.4473684"
              id="Line-Copy"
              stroke="#E0F0F3"
              strokeWidth="3"
              strokeLinecap="round"
            />
            <path
              d="M79.55,59.5 L89.45,61.5"
              id="Line-2"
              stroke="#E0F0F3"
              strokeWidth="3"
              strokeLinecap="round"
            />
            <path
              d="M72.5227273,73.5 L94.4772727,80.5"
              id="Line-3"
              stroke="#E0F0F3"
              strokeWidth="3"
              strokeLinecap="round"
            />
          </g>
        </g>
      </g>
    </svg>
  );
  /* eslint-enable max-len,react/jsx-max-props-per-line */
}
