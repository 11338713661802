import React from 'react';
import {
  Provider,
} from 'react-redux';
import {
  MuiThemeProvider,
  createMuiTheme,
} from '@material-ui/core/styles';
import {
  hot,
} from 'react-hot-loader/root';
import PropTypes from 'prop-types';
import { initializeIcons } from 'office-ui-fabric-react';

import MicrosoftTheme from 'Src/themes/microsoft';
import {
  CONTEXT,
} from 'Src/constants';
import store from 'Src/store';
import {
  setAuthToken,
  setContext,
} from 'Src/actions';
import startSagas from 'Src/sagas/start';

import App from './App';

function initializeApp({
  context,
  userIdentityToken,
}) {
  initializeIcons();
  startSagas();

  store.dispatch(setAuthToken(userIdentityToken));
  store.dispatch(setContext(context));

  const useMicrosoftTheme = context === CONTEXT.OUTLOOK_ADD_IN;
  const theme = useMicrosoftTheme ? MicrosoftTheme : createMuiTheme();

  return (
    <MuiThemeProvider theme={theme}>
      <Provider store={store}>
        <App />
      </Provider>
    </MuiThemeProvider>
  );
}

initializeApp.propTypes = {
  context: PropTypes.oneOf(Object.values(CONTEXT)).isRequired,
  userIdentityToken: PropTypes.string.isRequired,
};

export default hot(initializeApp);
