import {
  call,
} from 'redux-saga/effects';
import {
  List,
  Map,
} from 'immutable';
import {
  GrooveHTTPClient,
  HTTPMethod,
} from '@groove-labs/groove-http-client';

export default function* fetchAvailableMergeFields() {
  const response = yield call(
    GrooveHTTPClient.makeRequest,
    {
      path: 'api/v2/templates/available_merge_fields',
      method: HTTPMethod.GET,
    },
  );

  // TODO @corytheboyd Handle HTTP errors

  return response.message.get('data').reduce(
    (list, options, label) => list.push(Map({
      label,
      options,
    })),
    List(),
  );
}
