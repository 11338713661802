import React from 'react';
import {
  CircularProgress,
  Typography,
} from '@material-ui/core';
import {
  makeStyles,
} from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loader: {
    marginRight: theme.spacing(),
  },
}));

export default function Loader() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <CircularProgress className={classes.loader} />
      <Typography color="primary">
        Loading...
      </Typography>
    </div>
  );
}
