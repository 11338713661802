import React from 'react';
import { makeStyles } from '@material-ui/styles';

import CreateTemplateButton from 'Browser/Shared/CreateTemplateButton';

import TemplateSearchInput from './TemplateSearchInput';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    borderBottom: '1px solid #c8c6c5', // TODO @corytheboyd Consider this customization if/when we touch on theming
  },
  addButton: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

// TODO @corytheboyd Connect to state
function Header() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div>
        <TemplateSearchInput />
      </div>
      <div className={classes.addButton}>
        <CreateTemplateButton />
      </div>
    </div>
  );
}

export default Header;
