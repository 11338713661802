import {
  all,
  call,
  put,
} from 'redux-saga/effects';
import {
  Users,
} from '@groove-labs/groove-http-client';
import * as Sentry from '@sentry/browser';

import {
  setFolders,
  setCurrentUser,
  setStarLabel,
  fetchTemplates,
  setTemplates,
} from 'Src/actions';
import fetchTemplateFolders from 'Src/sagas/fetchTemplateFolders';
import fetchTemplatesInFolder from 'Src/sagas/fetchTemplatesInFolder';
import listToOrderedMap from 'Utils/immutable/listToOrderedMap';
import fetchStarLabel from 'Src/sagas/fetchStarLabel';
import User from 'Data/User';

import handleFetchingTemplate from './handleFetchingTemplate';

export default function* initializeData() {
  const [
    currentUserResponse,
    folders,
  ] = yield all([
    call(Users.getCurrentUser),
    call(fetchTemplateFolders),
  ]);

  const currentUser = new User({
    id: currentUserResponse.data.id,
    name: currentUserResponse.data.name,
    emailAddress: currentUserResponse.data.email,
    organizationId: currentUserResponse.data.orgId,
  });

  Sentry.configureScope(scope => scope.setUser({
    id: currentUser.id,
    email: currentUser.emailAddress,
  }));

  yield put(setCurrentUser(currentUser));

  // Note: This needs to happen after we put currentUser into state as it depends on
  // currentUser.id.
  const starLabel = yield call(fetchStarLabel);
  yield put(setStarLabel(starLabel));

  yield put(setFolders(listToOrderedMap(folders, f => f.id)));

  if (!folders.isEmpty()) {
    const defaultFolder = folders.find(folder => folder.isDefault, null, folders.first());
    yield put(fetchTemplates(defaultFolder.id));
    const templates = yield call(fetchTemplatesInFolder, defaultFolder.id);
    const selectedTemplateId = templates.isEmpty() ? null : templates.first().id;
    yield put(setTemplates({
      templates,
      folderId: defaultFolder.id,
      selectedTemplateId,
    }));
    yield call(handleFetchingTemplate, { payload: selectedTemplateId });
  }
}
