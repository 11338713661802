import React, { useCallback } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  ListItemText,
  Typography,
} from '@material-ui/core';
import {
  useDispatch, useSelector,
} from 'react-redux';
import ImmutablePropTypes from 'react-immutable-proptypes';

import viewsIcon from 'Src/assets/template-stat-icons/views.svg';
import clicksIcon from 'Src/assets/template-stat-icons/clicks.svg';
import repliesIcon from 'Src/assets/template-stat-icons/replies.svg';
import {
  getSelectedTemplateId,
} from 'Src/store/selectors';
import {
  setStarredForTemplate,
  setSelectedTemplateId,
  fetchSelectedTemplateData,
} from 'Src/actions';
import Template from 'Data/Template';
import StarrableListItem from 'Browser/Shared/StarrableListItem';

const useStyles = makeStyles(theme => ({
  subject: {
    color: '#33332d',
  },
  secondaryStatsContainer: {
    display: 'flex',
    '&> :not(:last-child)': {
      marginRight: theme.spacing(2),
    },
  },
  secondaryStatsIconContainer: {
    display: 'flex',
    alignItems: 'center',
    '&> :not(:last-child)': {
      marginRight: theme.spacing(0.5),
    },
  },
  icon: {
    paddingRight: theme.spacing(0.5),
  },
}));

/**
 * @param {string} percentage
 *
 * @return {string}
 * */
const renderPercentage = percentage => `${percentage * 100}%`;

function TemplateListItem({ template }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const selectedTemplateId = useSelector(getSelectedTemplateId);

  const handleTemplateSelection = useCallback(
    () => {
      dispatch(setSelectedTemplateId(template.id));
      dispatch(fetchSelectedTemplateData(template.id));
    },
    [dispatch, template.id],
  );
  const handleToggleStarred = useCallback(
    starred => dispatch(setStarredForTemplate({
      id: template.id,
      starred,
    })),
    [dispatch, template],
  );

  const isSelected = template.id === selectedTemplateId;
  const { isStarred } = template;

  return (
    <StarrableListItem
      onClick={handleTemplateSelection}
      onToggleStarred={handleToggleStarred}
      selected={isSelected}
      starred={isStarred}
    >
      <ListItemText
        primary={(
          <Typography
            variant="body2"
          >
            {template.name}
          </Typography>
        )}
        secondary={(
          <div>
            <Typography
              variant="caption"
              className={classes.subject}
            >
              Subject:&nbsp;
              {template.subject}
            </Typography>
            <div className={classes.secondaryStatsContainer}>
              <Typography
                classes={{
                  root: classes.secondaryStatsIconContainer,
                }}
                variant="caption"
              >
                <img
                  className={classes.icon}
                  src={viewsIcon}
                  alt="views"
                />
                {renderPercentage(template.openCount)}
              </Typography>
              <Typography
                classes={{
                  root: classes.secondaryStatsIconContainer,
                }}
                variant="caption"
              >
                <img
                  className={classes.icon}
                  src={clicksIcon}
                  alt="clicks"
                />
                {renderPercentage(template.linkClickCount)}
              </Typography>
              <Typography
                classes={{
                  root: classes.secondaryStatsIconContainer,
                }}
                variant="caption"
              >
                <img
                  className={classes.icon}
                  src={repliesIcon}
                  alt="replies"
                />
                {renderPercentage(template.replyCount)}
              </Typography>
            </div>
          </div>
        )}
      />
    </StarrableListItem>
  );
}

TemplateListItem.propTypes = {
  template: ImmutablePropTypes.recordOf(Template).isRequired,
};

export default TemplateListItem;
