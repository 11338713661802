import React, {
  useCallback,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import {
  makeStyles,
} from '@material-ui/styles';
import classNames from 'classnames';

import {
  getSelectedFolder,
  getIsTemplateDialogLoading,
  getTemplateDialogFolders,
} from 'Src/store/selectors';
import {
  createTemplate,
  fetchTemplateDialogData,
} from 'Src/actions';

import TemplateForm from './TemplateForm';
import Loader from './Loader';

const WIDTH = 600;
const HEIGHT = 600;

const useStyles = makeStyles({
  dialog: {
    minWidth: WIDTH,
    minHeight: HEIGHT,
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

function CreateTemplateDialog(props) {
  const {
    open,
    onClose,
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const selectedFolder = useSelector(getSelectedFolder);
  const isLoading = useSelector(getIsTemplateDialogLoading);
  const folders = useSelector(getTemplateDialogFolders);

  const handleClose = useCallback(() => onClose(), [onClose]);
  const handleDialogOpen = useCallback(() => dispatch(fetchTemplateDialogData()), [dispatch]);
  const handleSubmit = useCallback(({
    folder: folderId,
    name,
    subject,
    body,
  }) => {
    dispatch(
      createTemplate({
        folderId,
        name,
        subject,
        body,
      }),
    );
    onClose();
  }, [dispatch, onClose]);

  return (
    <Dialog
      // The `disableEnforceFocus` property prevents the MUI dialog from stealing focus from the
      // form inputs within it.
      // See:
      // https://material-ui.com/api/modal/
      // https://github.com/GrooveLabs/febes/pull/4061
      disableEnforceFocus

      open={open}
      fullWidth
      maxWidth="md"
      onClose={onClose}
      onEnter={handleDialogOpen}
      classes={{
        paper: classes.dialog,
      }}
    >
      <DialogTitle>
        Create Template
      </DialogTitle>
      <DialogContent className={classNames(
        {
          [classes.loading]: isLoading,
        },
      )}
      >
        { isLoading && <Loader /> }
        {
          /*
            TODO @sanchit replace with correct initial folder value:
            1. Either your personal folder
            2. The most recently created folder
          */
        }
        {
          !isLoading
            && !folders.isEmpty()
            && (
            <TemplateForm
              initialFolderId={(selectedFolder && selectedFolder.id) || folders.first().id}
              folders={folders}
              onClose={handleClose}
              onSubmit={handleSubmit}
            />
            )
        }
      </DialogContent>
    </Dialog>
  );
}

CreateTemplateDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
};

CreateTemplateDialog.defaultProps = {
  onClose: () => {},
};

export {
  WIDTH,
  HEIGHT,
};

export default CreateTemplateDialog;
