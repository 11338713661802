import React from 'react';
import {
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import CreateTemplateButton from 'Browser/Shared/CreateTemplateButton';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '&> :not(:last-child)': {
      marginBottom: theme.spacing(2),
    },
  },
}));

function EmptyFolderCTA() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="h5">
        No Templates
      </Typography>
      <CreateTemplateButton />
    </div>
  );
}

export default EmptyFolderCTA;
