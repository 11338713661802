import { call } from 'redux-saga/effects';
import { print } from 'graphql';
import { List } from 'immutable';
import {
  GrooveHTTPClient,
  HTTPMethod,
} from '@groove-labs/groove-http-client';

export default function* makeGraphQLRequest(query, variables = {}) {
  const response = yield call(GrooveHTTPClient.makeRequest, {
    path: 'api/graphql',
    method: HTTPMethod.POST,
    body: {
      query: print(query),
      variables: JSON.stringify(variables),
    },
  });

  const errors = response.message.get('errors', List());
  if (!errors.isEmpty()) {
    // TODO @corytheboyd Better handle GraphQL errors. For now, at least log them for
    //  ease of debugging.
    // eslint-disable-next-line no-console
    console.error('GraphQL request returned errors', errors, print(query));
  }

  return response;
}
