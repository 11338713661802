import {
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import { ACTION_TYPES } from 'Src/actions';
import handleSendUseTemplateMessageToParent from 'Src/sagas/handleSendUseTemplateMessageToParent';

import handleTemplateCreation from './handleTemplateCreation';
import handleTemplateUpdate from './handleTemplateUpdate';
import handleTemplateDeletion from './handleTemplateDeletion';
import handleSettingFolder from './handleSettingFolder';
import handleTemplateSearch from './handleTemplateSearch';
import handleSetStarredForTemplate from './handleSetStarredForTemplate';
import handleSetStarredForFolder from './handleSetStarredForFolder';
import handleFetchingTemplateDialogData from './handleFetchingTemplateDialogData';
import handleFetchingTemplate from './handleFetchingTemplate';
import handleLoggingError from './handleLoggingError';

function* watchSendUseTemplateMessageToParent() {
  yield takeEvery(ACTION_TYPES.SEND_USE_TEMPLATE_MESSAGE_TO_PARENT, handleSendUseTemplateMessageToParent);
}

function* watchSetStarredForFolder() {
  yield takeEvery(ACTION_TYPES.SET_STARRED_FOR_FOLDER, handleSetStarredForFolder);
}

function* watchSetStarredForTemplate() {
  yield takeEvery(ACTION_TYPES.SET_STARRED_FOR_TEMPLATE, handleSetStarredForTemplate);
}

function* watchTemplateCreation() {
  yield takeLatest(ACTION_TYPES.CREATE_TEMPLATE, handleTemplateCreation);
}

function* watchTemplateUpdate() {
  yield takeLatest(ACTION_TYPES.UPDATE_TEMPLATE, handleTemplateUpdate);
}

function* watchTemplateDeletion() {
  yield takeLatest(ACTION_TYPES.DELETE_TEMPLATE, handleTemplateDeletion);
}

function* watchSettingFolder() {
  yield takeLatest(ACTION_TYPES.SET_SELECTED_FOLDER_ID, handleSettingFolder);
}

function* watchFetchingSearchResults() {
  yield takeLatest(ACTION_TYPES.FETCH_SEARCH_RESULTS, handleTemplateSearch);
}

function* watchFetchingTemplateDialogData() {
  yield takeLatest(ACTION_TYPES.FETCH_TEMPLATE_DIALOG_DATA, handleFetchingTemplateDialogData);
}

function* watchFetchingSelectedTemplateData() {
  yield takeLatest(ACTION_TYPES.FETCH_SELECTED_TEMPLATE_DATA, handleFetchingTemplate);
}

function* watchLoggingError() {
  yield takeEvery(ACTION_TYPES.LOG_ERROR, handleLoggingError);
}

export {
  watchSendUseTemplateMessageToParent,
  watchSetStarredForFolder,
  watchSetStarredForTemplate,
  watchTemplateCreation,
  watchTemplateUpdate,
  watchTemplateDeletion,
  watchSettingFolder,
  watchFetchingSearchResults,
  watchFetchingTemplateDialogData,
  watchFetchingSelectedTemplateData,
  watchLoggingError,
};
