import {
  call,
} from 'redux-saga/effects';

import { STARRABLE_ENTITY_TYPES } from 'Src/constants';
import setStarredForEntity from 'Src/sagas/setStarredForEntity';

export default function* handleSetStarredForTemplate({ payload: {
  id,
  starred,
} }) {
  yield call(setStarredForEntity, {
    id,
    starred,
    type: STARRABLE_ENTITY_TYPES.TEMPLATE,
  });
}
