import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme({
  palette: {
    primary: {
      50: '#E2F5F8',
      100: '#C6EEF1',
      200: '#89DBE5',
      300: '#42CAD7',
      400: '#00B7CA',
      500: '#01879C',
      600: '#008A9D',
      700: '#017A8C',
      800: '#004049',
      900: '#0F3135',
      A100: '#F8E1B2',
      A200: '#FCB02B',
      A400: '#F19539',
      A700: '#FF8000',
      contrastDefaultColor: 'dark',
    },
  },
  typography: {
    fontFamily: [
      '"Segoe UI"',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  shape: {
    borderRadius: 0,
  },
  groove: {
    unit: 4,
  },
  overrides: {
    MuiIconButton: {
      root: {
        '&:hover': {
          backgroundColor: 'inherit',
        },
      },
    },
    MuiButton: {
      root: {
        minWidth: 88,
        textTransform: 'none',
      },
      contained: {
        boxShadow: 'none',
      },
    },
    MuiCheckbox: {
      root: {
        borderRadius: 0,
      },
    },
    MuiCardContent: {
      root: {
        paddingLeft: 16,
        paddingRight: 16,
      },
    },
    MuiInput: {
      root: {
        lineHeight: 'normal',
        border: '1px solid #c8c6c5',
      },
      input: {
        marginLeft: 12,
      },
      underline: {
        '&:after': {
          borderBottom: '2px solid #03A3BB',
        },
      },
    },
    MuiListItem: {
      root: {
        '&$selected': {
          backgroundColor: '#f4f4f4',
        },
      },
    },
    MuiTable: {
      root: {
        overflow: 'hidden',
      },
    },
  },
  props: {
    MuiInput: {
      disableUnderline: true,
    },
    MuiSwitch: {
      color: 'primary',
    },
    MuiCheckbox: {
      color: 'primary',
    },
  },
});
