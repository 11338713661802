import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { useSelector } from 'react-redux';

import TemplateListItem from 'Browser/TemplateList/TemplateListItem';
import { getTemplatesForSelectedFolder } from 'Src/store/selectors';
import ListHeader from 'Browser/Shared/ListHeader';

const useStyles = makeStyles({
  root: {
    width: 300,
  },
});

function TemplateList() {
  const classes = useStyles();
  const templates = useSelector(getTemplatesForSelectedFolder);

  if (templates.isEmpty()) {
    return null;
  }

  return (
    <div className={classes.root}>
      <ListHeader>
        Templates
      </ListHeader>
      {templates.toList().map(template => (
        <TemplateListItem
          key={template.id}
          template={template}
        />
      ))}
    </div>
  );
}

export default TemplateList;
