import {
  call,
  fork,
  put,
} from 'redux-saga/effects';

import {
  watchLoggingError,
} from 'Src/sagas/watchers';
import {
  setLoadingState,
} from 'Src/actions';

import safe from './safe';
import upstart from './upstart';

export default function* root() {
  yield put(setLoadingState(true));
  yield fork(watchLoggingError);

  try {
    yield safe(call(upstart));
  } finally {
    yield put(setLoadingState(false));
  }
}
