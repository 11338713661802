import {
  call,
  put,
  select,
} from 'redux-saga/effects';
import {
  GrooveHTTPClient,
  HTTPMethod,
} from '@groove-labs/groove-http-client';
import HTTPStatusCodes from 'http-status-codes';
import toNumber from 'lodash-es/toNumber';

import {
  fetchTemplates,
  setTemplates,
  setSnackbarMessage,
  failedTemplateDeletion,
  deletedTemplate,
} from 'Src/actions';
import {
  getSelectedFolderId,
  getIsInSearchMode,
} from 'Src/store/selectors';
import handleFetchingTemplate from 'Src/sagas/handleFetchingTemplate';
import fetchTemplatesInFolder from 'Src/sagas/fetchTemplatesInFolder';
import fetchSearchResults from 'Src/sagas/fetchSearchResults';

export default function* handleTemplateDeletion({ payload: id }) {
  try {
    yield call(
      GrooveHTTPClient.makeRequest,
      {
        path: `api/v2/templates/${id}`,
        method: HTTPMethod.DELETE,
      },
    );
  } catch (error) {
    yield put(failedTemplateDeletion());

    if (error && error.response && error.response.get('status') === HTTPStatusCodes.BAD_REQUEST) {
      // TODO: @jaebradley replace placeholder text with design error
      yield put(setSnackbarMessage('Oops - cannot delete template'));
      return null;
    }

    throw error;
  }

  yield put(deletedTemplate());

  const isInSearchMode = yield select(getIsInSearchMode);
  if (isInSearchMode) {
    yield call(fetchSearchResults);
    return null;
  }

  const selectedFolderId = yield select(getSelectedFolderId);
  yield put(fetchTemplates(selectedFolderId));

  const templates = yield call(fetchTemplatesInFolder, selectedFolderId);
  const selectedTemplateId = templates.isEmpty() ? null : toNumber(templates.first().id);
  yield put(setTemplates({
    templates,
    folderId: selectedFolderId,
    selectedTemplateId,
  }));
  yield call(handleFetchingTemplate, { payload: selectedTemplateId });
  return null;
}
