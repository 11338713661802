import {
  createSelector,
} from 'reselect';
import {
  OrderedMap,
} from 'immutable';

const getContext = state => state.get('context');
const getCurrentUser = state => state.get('currentUser');
const getFolders = state => state.get('folders');
const getSelectedFolderId = state => state.get('selectedFolderId');
const getIsLoading = state => state.getIn(['isLoading']);
const getSearchTerm = state => state.getIn(['search', 'term']);
const getIsFetchingSearchResults = state => state.getIn(['search', 'isFetching']);
const getStarLabel = state => state.getIn(['starLabel']);
const getSelectedTemplateId = state => state.getIn(['selectedTemplate', 'id']);
const getSelectedTemplateData = state => state.getIn(['selectedTemplate', 'data']);
const getIsFetchingTemplateData = state => state.getIn(['selectedTemplate', 'isFetching']);
const getIsFetchingTemplates = state => state.getIn(['isFetchingTemplates']);

const makeGetTemplatesForFolder = folderId => createSelector(
  getFolders,
  folders => folders.getIn([folderId, 'templates'], OrderedMap()),
);

const getTemplatesForSelectedFolder = createSelector(
  [getSelectedFolderId, getFolders],
  (selectedFolderId, folders) => folders.getIn([selectedFolderId, 'templates'], OrderedMap()),
);

const getSelectedTemplate = createSelector(
  [getTemplatesForSelectedFolder, getSelectedTemplateId],
  (templates, id) => templates.get(id, null),
);

const getIsSelectedFolderEmpty = createSelector(
  getTemplatesForSelectedFolder,
  templates => templates.isEmpty(),
);

const getSelectedFolder = createSelector(
  [getSelectedFolderId, getFolders],
  (selectedFolderId, folders) => folders.get(selectedFolderId, null),
);

const getIsInSearchMode = createSelector(
  [getSearchTerm],
  searchTerm => !!searchTerm,
);

const getTemplateDialogFolders = state => state.getIn(['templateDialog', 'folders', 'data']);
const getIsTemplateDialogLoading = state => state.getIn(['templateDialog', 'isLoading']);
const getIsSnackbarOpen = state => state.getIn(['snackbar', 'isOpen']);
const getSnackbarMessage = state => state.getIn(['snackbar', 'message']);
const getShouldShowErrorPage = state => state.getIn(['showErrorPage']);

const getTemplateDialogMergeFields = createSelector(
  state => state.getIn(['templateDialog', 'mergeFields']),
  mergeFields => mergeFields.toJS(),
);

export {
  getContext,
  getCurrentUser,
  getFolders,
  getIsSelectedFolderEmpty,
  getIsLoading,
  getSelectedFolderId,
  getSelectedTemplateId,
  getSelectedTemplate,
  getSelectedTemplateData,
  getIsFetchingTemplateData,
  getTemplatesForSelectedFolder,
  getSelectedFolder,
  getIsInSearchMode,
  makeGetTemplatesForFolder,
  getSearchTerm,
  getIsFetchingSearchResults,
  getStarLabel,
  getTemplateDialogFolders,
  getTemplateDialogMergeFields,
  getIsTemplateDialogLoading,
  getIsFetchingTemplates,
  getIsSnackbarOpen,
  getSnackbarMessage,
  getShouldShowErrorPage,
};
