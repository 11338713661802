import {
  call,
  select,
} from 'redux-saga/effects';
import {
  GrooveHTTPClient,
  HTTPMethod,
} from '@groove-labs/groove-http-client';
import { decamelizeKeys } from 'humps';

import {
  getStarLabel,
} from 'Src/store/selectors';

export default function* unstarEntity({
  id,
  type,
}) {
  const starLabel = yield select(getStarLabel);

  const labelableType = type;
  const labelableId = id;
  const labelIds = [starLabel.id];

  // TODO @corytheboyd Should we move CRUD operations to GraphQL mutations?
  yield call(
    GrooveHTTPClient.makeRequest,
    {
      path: 'api/v2/label_instances/batch_destroy',
      method: HTTPMethod.DELETE,
      queryParameters: decamelizeKeys({
        labelableId,
        labelableType,
        labelIds,
      }),
    },
  );
}
