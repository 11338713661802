import {
  List,
} from 'immutable';

import listToOrderedMap from 'Utils/immutable/listToOrderedMap';

import Template from './Template';

function transformTemplateData(data) {
  return new Template({
    ...data,
    body: data.htmlBody,
    linkClickCount: data.timesLinkClicked,
    openCount: data.openedCount,
    replyCount: data.repliedCount,
    folderId: data.templateFolderId,
  });
}

function transformTemplatesData(data) {
  return listToOrderedMap(
    List(data.map(template => transformTemplateData(template))),
    template => template.id,
  );
}

export {
  transformTemplateData,
  transformTemplatesData,
};
