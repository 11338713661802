export function isProduction() {
  return process.env.NODE_ENV === 'production';
}

export function isDevelopment() {
  return !isProduction();
}

/**
 * Fetch your auth token from an environment variable. Useful for developing the application
 * outside of the Office Add-in context, which requires the parent window to provide the
 * token.
 *
 * @return {string|undefined}
 * */
export function getAuthTokenOverride() {
  return process.env.AUTH_TOKEN_OVERRIDE;
}

/**
 * @return {boolean}
 * */
export function hasAuthTokenOverride() {
  return !!getAuthTokenOverride();
}

/**
 * See: getAuthTokenOverride
 *
 * @return {string|undefined}
 * */
export function getContextOverride() {
  return process.env.CONTEXT_OVERRIDE;
}

/**
 * See: getAuthTokenOverride
 *
 * @return {boolean}
 * */
export function hasContextOverride() {
  return !!getContextOverride();
}

export function getSentryDSN() {
  return process.env.SENTRY_DSN;
}
