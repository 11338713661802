import {
  call,
  put,
  select,
} from 'redux-saga/effects';
import toNumber from 'lodash-es/toNumber';

import {
  fetchTemplates,
  setTemplates,
} from 'Src/actions';
import {
  getIsInSearchMode,
  makeGetTemplatesForFolder,
} from 'Src/store/selectors';

import fetchTemplatesInFolder from './fetchTemplatesInFolder';
import handleFetchingTemplate from './handleFetchingTemplate';

export default function* handleSettingFolder({ payload: folderId }) {
  const isInSearchMode = yield select(getIsInSearchMode);
  yield put(fetchTemplates(folderId));

  let templates;
  if (isInSearchMode) {
    const templatesSelector = makeGetTemplatesForFolder(folderId);
    templates = yield select(templatesSelector);
  } else {
    templates = yield call(fetchTemplatesInFolder, folderId);
  }

  const selectedTemplateId = templates.isEmpty() ? null : toNumber(templates.first().id);
  yield put(setTemplates({
    templates,
    folderId,
    selectedTemplateId,
  }));

  yield call(handleFetchingTemplate, { payload: selectedTemplateId });
}
