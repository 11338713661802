import React, {
  useCallback,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import {
  useForm,
  useField,
} from 'react-final-form-hooks';
import { makeStyles } from '@material-ui/styles';
import {
  FormControl,
  MenuItem,
  Input,
  InputLabel,
  Select,
  Button,
  DialogActions,
} from '@material-ui/core';
import { WysiwygEditor } from '@groove-labs/groove-wysiwyg-editor';
import { useSelector } from 'react-redux';

import Folder from 'Data/Folder';
import { getTemplateDialogMergeFields } from 'Src/store/selectors';

const useStyles = makeStyles(theme => ({
  root: {},
  top: {
    display: 'flex',
    '&> :not(:last-child)': {
      marginRight: theme.spacing(1),
    },
  },
  basicPadding: {
    padding: theme.spacing(1, 0),
  },
  leftMargin: {
    marginLeft: theme.spacing(1),
  },
  buttons: {
    display: 'flex',
    justifyItems: 'flex-start',
    width: '100%',
  },
}));

function TemplateForm({
  initialFolderId,
  folders,
  initialBody,
  initialName,
  initialSubject,
  onClose,
  onSubmit,
}) {
  const classes = useStyles();
  const handleOnClose = useCallback(onClose, []);

  const [
    body,
    setBody,
  ] = useState(initialBody);
  const handleEditorChange = useCallback(value => setBody(value), []);

  const handleSubmit = useCallback((values) => {
    const mergedValues = {
      ...values,
      body,
    };
    onSubmit(mergedValues);
  }, [body, onSubmit]);

  const {
    form,
    handleSubmit: handleFormSubmit,
  } = useForm({
    onSubmit: handleSubmit,
    initialValues: {
      folder: initialFolderId,
      name: initialName,
      subject: initialSubject,
    },
  });

  const folder = useField('folder', form);
  const name = useField('name', form);
  const subject = useField('subject', form);

  const mergeFields = useSelector(getTemplateDialogMergeFields);

  return (
    <form onSubmit={handleFormSubmit}>
      <div className={classes.root}>
        <div className={classes.top}>
          <FormControl fullWidth>
            <InputLabel
              shrink
              htmlFor="folder"
            >
              Template Folder
            </InputLabel>
            <Select
              input={<Input id="folder" />}
              name={folder.input.name}
              value={folder.input.value}
              onBlur={folder.input.onBlur}
              onChange={folder.input.onChange}
              onFocus={folder.input.onFocus}
            >
              {
                folders.entrySeq().map(([folderId, folderData]) => (
                  <MenuItem
                    key={folderId}
                    value={folderId}
                  >
                    {folderData.name}
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel
              shrink
              htmlFor="name"
            >
              Template Name
            </InputLabel>
            <Input
              required
              id="name"
              name={name.input.name}
              placeholder="Name of the template"
              value={name.input.value}
              onBlur={name.input.onBlur}
              onChange={name.input.onChange}
              onFocus={name.input.onFocus}
            />
          </FormControl>
        </div>
        <div>
          <FormControl
            className={classes.basicPadding}
            fullWidth
          >
            <Input
              required
              id="subject"
              name={subject.input.name}
              placeholder="Email subject for the template"
              value={subject.input.value}
              onBlur={subject.input.onBlur}
              onChange={subject.input.onChange}
              onFocus={subject.input.onFocus}
            />
          </FormControl>
        </div>
        <WysiwygEditor
          initialValue={initialBody}
          mergeFields={mergeFields}
          onChange={handleEditorChange}
        />
        <DialogActions className={classes.basicPadding}>
          <div className={classes.buttons}>
            <Button
              color="primary"
              variant="contained"
              type="submit"
            >
              Save Template
            </Button>
            <Button
              color="default"
              variant="contained"
              onClick={handleOnClose}
              className={classes.leftMargin}
            >
              Close
            </Button>
          </div>
        </DialogActions>
      </div>
    </form>
  );
}

TemplateForm.propTypes = {
  initialFolderId: PropTypes.number.isRequired,
  folders: ImmutablePropTypes.orderedMapOf(PropTypes.number, Folder),
  initialBody: PropTypes.string,
  initialName: PropTypes.string,
  initialSubject: PropTypes.string,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

TemplateForm.defaultProps = {
  initialBody: null,
  initialName: null,
  initialSubject: null,
  folders: [],
  onClose: () => {},
  onSubmit: () => {},
};

export default TemplateForm;
