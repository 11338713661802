const CONTEXT = Object.freeze({
  OUTLOOK_ADD_IN: 'OUTLOOK_ADD_IN',
});

const CONTEXT_BASE_URL_MAPPING = Object.freeze({
  [CONTEXT.OUTLOOK_ADD_IN]: process.env.OUTLOOK_ADD_IN_BASE_URL,
});

const STARRABLE_ENTITY_TYPES = Object.freeze({
  FOLDER: 'FOLDER',
  TEMPLATE: 'TEMPLATE',
});

// Mapping of internal entity type constants to respective types used by the GE labelable API.
const STARRABLE_ENTITY_TYPES_TO_LABELABLE_TYPE = Object.freeze({
  [STARRABLE_ENTITY_TYPES.FOLDER]: 'template_folder',
  [STARRABLE_ENTITY_TYPES.TEMPLATE]: 'template',
});

export {
  CONTEXT,
  CONTEXT_BASE_URL_MAPPING,
  STARRABLE_ENTITY_TYPES,
  STARRABLE_ENTITY_TYPES_TO_LABELABLE_TYPE,
};
