import {
  call,
  take,
} from 'redux-saga/effects';
import { GrooveHTTPClient } from '@groove-labs/groove-http-client';

import {
  GROOVE_ACCESS_TOKEN_KEY_NAME,
  setAccessToken,
} from 'Data/localStorage';
import { ACTION_TYPES } from 'Src/actions';

export default function* initializeHttpClient() {
  const { payload: userIdentityToken } = yield take(ACTION_TYPES.SET_AUTH_TOKEN);

  // TODO @corytheboyd Why?
  yield call(setAccessToken, userIdentityToken);

  GrooveHTTPClient.configure({
    baseURL: process.env.SERVER_BASE_URL,
    localStorageAuthorizationTokenKeyName: GROOVE_ACCESS_TOKEN_KEY_NAME,
    defaultHeaders: Object.freeze({
      'X-Microsoft-Exchange-Identity-Token': userIdentityToken,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    }),
  });
}
