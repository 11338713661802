import 'core-js/stable';
import 'regenerator-runtime/runtime';

import 'react-hot-loader';
import Immutable from 'immutable';
import React from 'react';
import * as Sentry from '@sentry/browser';

import ReactDOM from 'react-dom';
import {
  isDevelopment,
  getAuthTokenOverride,
  hasAuthTokenOverride,
  getContextOverride,
  hasContextOverride,
  getSentryDSN,
} from 'Src/environment';
import { CONTEXT } from 'Src/constants';

import initializeApp from './components/initializeApp';

Sentry.init({ dsn: getSentryDSN() });

function isValidMessage(message) {
  return !!message
    && !!message.data
    && !!message.data.context;
}

function handleMessage(message) {
  if (isValidMessage(message)) {
    // We don't need this event listener anymore, so just tear it down.
    window.removeEventListener('message', handleMessage);

    const {
      userIdentityToken,
      context,
      userId,
    } = message.data;

    if (context === CONTEXT.OUTLOOK_ADD_IN && !userIdentityToken) {
      throw new Error(`The ${CONTEXT.OUTLOOK_ADD_IN} context requires that userIdentityToken is provided by the initialization postMessage, but it is missing`);
    }

    if (userId) {
      // Set the current user id for Sentry logging as soon as possible
      // This allows user-level logging before user-specific data is fetched
      Sentry.configureScope((scope) => {
        scope.setUser({ id: userId });
      });
    }

    ReactDOM.render(
      initializeApp({
        // TODO @corytheboyd This needs to not be coupled to Microsoft (other contexts will
        //  probably auth with GE JWT tokens)
        userIdentityToken,
        context,
      }),
      document.getElementById('app'),
    );
  }
}

if (isDevelopment()) {
  // eslint-disable-next-line import/no-extraneous-dependencies,global-require
  const installImmutableDevTools = require('immutable-devtools');
  installImmutableDevTools(Immutable);
}

window.addEventListener('message', handleMessage);

ReactDOM.render(
  <div>
    Loading...
  </div>,
  document.getElementById('app'),
);

// Override the authentication step for developing the application in a standalone environment
if (isDevelopment() && hasAuthTokenOverride()) {
  let context = 'DEFAULT';
  if (hasContextOverride()) {
    context = getContextOverride();
  }

  window.postMessage({
    authToken: getAuthTokenOverride(),
    context,
  }, window.location.origin);
}
