import React from 'react';
import PropTypes from 'prop-types';
import {
  Star as StarIcon,
  StarBorder as StarBorderIcon,
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  starred: {
    color: '#fcb02b',
  },
});

function Star({
  starred,
  onClick,
}) {
  const classes = useStyles();

  return (
    <div
      onClick={onClick}
      onKeyUp={onClick}
      role="button"
      tabIndex={0}
    >
      {starred && (
        <StarIcon
          fontSize="small"
          className={classes.starred}
        />
      )}
      {!starred && (
        <StarBorderIcon
          fontSize="small"
        />
      )}
    </div>
  );
}

Star.propTypes = {
  onClick: PropTypes.func,
  starred: PropTypes.bool,
};

Star.defaultProps = {
  onClick: () => {},
  starred: false,
};

export default Star;
