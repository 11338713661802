import React, { useState } from 'react';
import {
  Add,
} from '@material-ui/icons';
import {
  Button,
} from '@material-ui/core';

import CreateTemplateDialog from 'Browser/CreateTemplateDialog';

function CreateTemplateButton() {
  const [isAddTemplateDialogOpen, setIsAddTemplateDialogOpen] = useState(false);

  function handleOpen() {
    setIsAddTemplateDialogOpen(true);
  }

  function handleClose() {
    setIsAddTemplateDialogOpen(false);
  }

  return (
    <>
      <Button
        color="primary"
        onClick={handleOpen}
      >
        <Add />
        Create Template
      </Button>

      <CreateTemplateDialog
        open={isAddTemplateDialogOpen}
        onClose={handleClose}
      />
    </>
  );
}

export default CreateTemplateButton;
