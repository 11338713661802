import {
  call,
} from 'redux-saga/effects';
import { List } from 'immutable';
import toNumber from 'lodash-es/toNumber';

import Template from 'Src/data/Template';
import makeGraphQLRequest from 'Src/sagas/makeGraphQLRequest';

import query from './query.graphql';

export default function* fetchTemplate(templateId) {
  const response = yield call(makeGraphQLRequest, query, { templateId });
  const templates = response.message.getIn(['data', 'templates'], List());
  if (!templates.isEmpty()) {
    const firstTemplate = templates.first().toJS();
    return new Template({
      ...firstTemplate,
      id: toNumber(firstTemplate.id),
    });
  }

  return null;
}
