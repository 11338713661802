import {
  call,
  put,
  select,
} from 'redux-saga/effects';
import {
  GrooveHTTPClient,
  HTTPMethod,
} from '@groove-labs/groove-http-client';

import {
  fetchTemplates,
  setTemplates,
  failedTemplateUpdate,
  updatedTemplate,
} from 'Src/actions';
import {
  getIsInSearchMode,
} from 'Src/store/selectors';

import fetchTemplatesInFolder from './fetchTemplatesInFolder';
import fetchSearchResults from './fetchSearchResults';
import handleFetchingTemplate from './handleFetchingTemplate';

export default function* handleTemplateUpdate({ payload }) {
  const {
    id,
    folderId,
    name,
    subject,
    body,
  } = payload;

  try {
    yield call(
      GrooveHTTPClient.makeRequest,
      {
        path: `api/v2/templates/${id}`,
        method: HTTPMethod.PUT,
        body: {
          name,
          subject,
          html_body: body,
          template_folder_id: folderId,
        },
      },
    );
  } catch (e) {
    yield put(failedTemplateUpdate());
    throw e;
  }

  yield put(updatedTemplate());

  const isInSearchMode = yield select(getIsInSearchMode);
  if (isInSearchMode) {
    yield call(fetchSearchResults);
    return;
  }

  yield put(fetchTemplates(folderId));

  const templates = yield call(fetchTemplatesInFolder, folderId);
  yield put(setTemplates({
    templates,
    folderId,
    selectedTemplateId: id,
  }));

  yield call(handleFetchingTemplate, { payload: id });
}
