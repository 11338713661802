import {
  fromJS,
  OrderedMap,
} from 'immutable';

import { ACTION_TYPES } from 'Src/actions';

const INITIAL_STATE = fromJS({
  isLoading: true,
  authToken: null,
  context: null,
  selectedFolderId: null,
  selectedTemplate: {
    id: null,
    data: null,
    isFetching: false,
  },
  folders: OrderedMap(),
  isFetchingTemplates: false,
  currentUser: null,
  search: {
    term: '',
    isFetching: '',
  },
  starLabel: null,
  templateDialog: {
    isLoading: false,
    folders: {
      data: OrderedMap(),
    },
    mergeFields: [],
  },
  snackbar: {
    message: null,
    isOpen: false,
  },
  showErrorPage: false,
});

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ACTION_TYPES.SET_AUTH_TOKEN: {
      return state.set('authToken', action.payload);
    }

    case ACTION_TYPES.SET_CONTEXT: {
      return state.set('context', action.payload);
    }

    case ACTION_TYPES.SET_FOLDERS: {
      return state
        .set('folders', action.payload)
        .setIn(['search', 'isFetching'], false);
    }

    case ACTION_TYPES.SET_ALL_FOLDER_DATA: {
      return state
        .setIn(['folders'], action.payload.folders)
        .setIn(['selectedFolderId'], action.payload.selectedFolderId)
        .setIn(['selectedTemplate', 'id'], action.payload.selectedTemplateId);
    }

    case ACTION_TYPES.SET_LOADING_STATE: {
      return state.setIn(['isLoading'], action.payload);
    }

    case ACTION_TYPES.SET_SELECTED_TEMPLATE_ID: {
      return state.setIn(['selectedTemplate', 'id'], action.payload);
    }

    case ACTION_TYPES.SET_CURRENT_USER: {
      return state.setIn(['currentUser'], action.payload);
    }

    case ACTION_TYPES.SEARCH_TEMPLATES: {
      return state.setIn(['searchTerm'], action.payload);
    }

    case ACTION_TYPES.SET_STAR_LABEL: {
      return state.setIn(['starLabel'], action.payload);
    }

    case ACTION_TYPES.SET_TEMPLATE_DIALOG_DATA: {
      return state
        .setIn(['templateDialog', 'folders', 'data'], action.payload.folders)
        .setIn(['templateDialog', 'mergeFields'], action.payload.mergeFields)
        .setIn(['templateDialog', 'isLoading'], false);
    }

    case ACTION_TYPES.FETCH_TEMPLATE_DIALOG_DATA: {
      return state.setIn(['templateDialog', 'isLoading'], true);
    }

    case ACTION_TYPES.SET_SELECTED_TEMPLATE_DATA: {
      return state
        .setIn(['selectedTemplate', 'id'], action.payload.id)
        .setIn(['selectedTemplate', 'data'], action.payload)
        .setIn(['selectedTemplate', 'isFetching'], false);
    }

    case ACTION_TYPES.FETCH_SELECTED_TEMPLATE_DATA: {
      return state.setIn(['selectedTemplate', 'isFetching'], true);
    }

    case ACTION_TYPES.FETCH_TEMPLATES: {
      return state.setIn(['isFetchingTemplates'], true);
    }

    case ACTION_TYPES.SET_TEMPLATES: {
      return state
        .setIn(
          ['folders', action.payload.folderId, 'templates'],
          action.payload.templates,
        )
        .setIn(['isFetchingTemplates'], false)
        .setIn(['selectedFolderId'], action.payload.folderId)
        .setIn(['selectedTemplate', 'id'], Number(action.payload.selectedTemplateId))
        .setIn(['selectedTemplate', 'data'], null)
        .setIn(['selectedTemplate', 'isFetching'], true);
    }

    case ACTION_TYPES.FETCH_SEARCH_RESULTS: {
      return state
        .setIn(['search', 'term'], action.payload)
        .setIn(['search', 'isFetching'], true)
        .setIn(['selectedTemplate', 'id'], null)
        .setIn(['selectedTemplate', 'data'], null)
        .setIn(['selectedTemplate', 'isFetching'], true);
    }

    case ACTION_TYPES.SET_SEARCH_RESULTS: {
      return state
        .setIn(['folders'], action.payload.folders)
        .setIn(['selectedFolderId'], action.payload.selectedFolderId)
        .setIn(['selectedTemplate', 'id'], action.payload.selectedTemplateId)
        .setIn(['search', 'isFetching'], false);
    }

    case ACTION_TYPES.OPEN_SNACKBAR: {
      return state
        .setIn(['snackbar', 'message'], action.payload)
        .setIn(['snackbar', 'isOpen'], true);
    }

    case ACTION_TYPES.CLOSE_SNACKBAR: {
      return state
        .setIn(['snackbar', 'message'], null)
        .setIn(['snackbar', 'isOpen'], false);
    }

    case ACTION_TYPES.SHOW_ERROR_PAGE: {
      return state.setIn(['showErrorPage'], action.payload);
    }

    case ACTION_TYPES.CREATE_TEMPLATE:
    case ACTION_TYPES.UPDATE_TEMPLATE:
    case ACTION_TYPES.DELETE_TEMPLATE: {
      return state.setIn(['templateDialog', 'isLoading'], true);
    }

    case ACTION_TYPES.FAILED_TEMPLATE_CREATION:
    case ACTION_TYPES.FAILED_TEMPLATE_UPDATE:
    case ACTION_TYPES.FAILED_TEMPLATE_DELETION:
    case ACTION_TYPES.CREATED_TEMPLATE:
    case ACTION_TYPES.UPDATED_TEMPLATE:
    case ACTION_TYPES.DELETED_TEMPLATE: {
      return state.setIn(['templateDialog', 'isLoading'], false);
    }

    default:
      return state;
  }
}
