import {
  call,
} from 'redux-saga/effects';

import fetchSearchResults from 'Src/sagas/fetchSearchResults';

import initializeData from './initializeData';

export default function* handleTemplateSearch({ payload: searchTerm }) {
  if (!searchTerm) {
    yield call(initializeData);
    return;
  }

  yield call(fetchSearchResults);
}
