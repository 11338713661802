import {
  call,
  put,
} from 'redux-saga/effects';

import {
  setSelectedTemplateData,
} from 'Src/actions';

import fetchTemplate from './fetchTemplate';

export default function* handleFetchingTemplate({ payload: templateId }) {
  const template = yield call(fetchTemplate, String(templateId));
  if (template) {
    yield put(setSelectedTemplateData(template));
  }
}
