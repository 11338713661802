import {
  Record,
  OrderedMap,
} from 'immutable';

const defaults = {
  id: null,
  isDefault: false,
  isEditable: false,
  isSharedWithOrganization: false,
  isStarred: false,
  name: null,
  user: null,
  templates: OrderedMap(),
  isShared: false,
  isOwnedByCurrentUser: false,
};

export default class Folder extends Record(defaults) {}
