import {
  call,
  select,
} from 'redux-saga/effects';
import { decamelizeKeys } from 'humps';
import { List } from 'immutable';
import {
  GrooveHTTPClient,
  HTTPMethod,
} from '@groove-labs/groove-http-client';

import { getCurrentUser } from 'Src/store/selectors';
import Label from 'Data/Label';

export default function* fetchStarLabel() {
  const currentUser = yield select(getCurrentUser);

  const response = yield call(
    GrooveHTTPClient.makeRequest,
    {
      path: 'api/v2/labels',
      method: HTTPMethod.GET,
      queryParameters: decamelizeKeys({
        labelOwnerType: 'User',
        labelOwnerId: currentUser.id,
        categories: ['groove_system'],
      }),
    },
  );
  // TODO @coryheboyd Handle HTTP errors

  const starredLabel = response.message
    .get('data', List())
    .find(label => label.get('name') === 'Starred');

  return new Label(starredLabel);
}
