import {
  Record,
} from 'immutable';

const defaults = {
  id: null,
  name: '',
  emailAddress: '',
  organizationId: null,
};

export default class User extends Record(defaults) {}
