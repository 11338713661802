import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';

import Template from 'Src/data/Template';

const BORDER_GREY = '#c8c6c5';
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  subject: {
    padding: theme.spacing(2),
    borderBottom: `1px solid ${BORDER_GREY}`,
  },
  basicPadding: {
    padding: theme.spacing(2),
  },
}));

function Body({ template }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.subject}>
        <Typography variant="body1">
          {template.subject}
        </Typography>
      </div>
      <Typography
        component="span"
        variant="body2"
      >
        <div
          /* eslint-disable-next-line react/no-danger */
          dangerouslySetInnerHTML={{ __html: template.body }}
          className={classes.basicPadding}
        />
      </Typography>
    </div>
  );
}


Body.propTypes = {
  template: PropTypes.instanceOf(Template),
};

Body.defaultProps = {
  template: new Template(),
};

export default Body;
